@use "@wojo/ui/mixins" as *;

.cover-container,
.cover-container img {
    display: flex;
    height: 100%;
    width: 100%;
}

.cover-container img {
    object-fit: cover;
    object-position: var(--object-position, center);

    @include for-tablet-up {
        object-position: var(--object-position-desktop, center);
    }
}
